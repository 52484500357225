<template>
    <el-container>
        <el-header class="header flex-center">
            <div class="logo"></div>
        </el-header>
        <el-container v-if="show">
            <el-main class="mt-6" width="70%">
                <div class="content" v-if="loading">
                    <div class="name">Vendor</div>
                    <div class="mt-6">
                        <Step1
                            :disabled="true"
                            :activeIndex.sync="activeIndex"
                        />
                    </div>
                    <div v-if="data && data.isNeedStepTwo != 0">
                        <Step2 :disabled="true" :activeIndex="activeIndex" />
                    </div>
                </div>
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
import Step1 from "../components/Step1.vue";
import Step2 from "../components/Step2.vue";
import API from "../api/api";
export default {
    name: "Details",
    components: {
        Step1,
        Step2,
    },
    data() {
        return {
            type: 1,
            show: null,
            activeIndex: 0,
            data: {},
            loading: false,
        };
    },
    async created() {
        localStorage.clear();
        const result = await Promise.all([
            this.getDataByEmail(),
            this.ownership_structure(),
            this.product_scope(),
            this.key_market_customers(),
            this.in_house_processes(),
            this.sex(),
            this.quality_management_systems(),
            this.laboratory(),
            this.export_port(),
            this.design_capability(),
            this.capacity_level(),
            this.business_type(),
            this.accreditation(),
            this.yn_dict(),
        ]);
        this.loading = result.every((items) => items);
    },
    methods: {
        async getDataByEmail() {
            const params = {
                email: this.$route.query.email,
            };
            const res = await API.getDataByEmail(params);

            const { data } = res;

            this.data = data;

            localStorage.setItem("dataList", JSON.stringify(data));

            this.show = true;
            return true;
        },
        async ownership_structure() {
            const res = await this.$http.getByDictType({
                dictType: "ownership_structure",
            });

            let data = JSON.stringify(res.data);
            localStorage.setItem("ownership_structure", data);
            return true;
        },
        async product_scope() {
            const res = await this.$http.getByDictType({
                dictType: "product_scope",
            });
            let data = res.data.filter((o) => o.childArray.length > 0);
            let data1 = JSON.stringify(data);
            localStorage.setItem("product_scope", data1);
            return true;
        },
        async key_market_customers() {
            const res = await this.$http.getByDictType({
                dictType: "key_market_customers",
            });

            let data = res.data.filter((o) => o.childArray.length > 0);

            let data1 = JSON.stringify(data);
            localStorage.setItem("key_market_customers", data1);
            return true;
        },
        async in_house_processes() {
            const res = await this.$http.getByDictType({
                dictType: "in_house_processes",
            });
            let data = JSON.stringify(res.data);
            localStorage.setItem("in_house_processes", data);
            return true;
        },
        async sex() {
            const res = await this.$http.getByDictType({
                dictType: "sex",
            });
            let data = JSON.stringify(res.data);
            localStorage.setItem("sex", data);
            return true;
        },
        async quality_management_systems() {
            const res = await this.$http.getByDictType({
                dictType: "quality_management_systems",
            });
            let data = JSON.stringify(res.data);
            localStorage.setItem("quality_management_systems", data);
            return true;
        },
        async laboratory() {
            const res = await this.$http.getByDictType({
                dictType: "laboratory",
            });
            let data = JSON.stringify(res.data);
            localStorage.setItem("laboratory", data);
            return true;
        },
        async export_port() {
            const res = await this.$http.getByDictType({
                dictType: "export_port",
            });
            let data = JSON.stringify(res.data);
            localStorage.setItem("export_port", data);
            return true;
        },
        async design_capability() {
            const res = await this.$http.getByDictType({
                dictType: "design_capability",
            });
            let data = JSON.stringify(res.data);
            localStorage.setItem("design_capability", data);
            return true;
        },
        async capacity_level() {
            const res = await this.$http.getByDictType({
                dictType: "capacity_level",
            });
            let data = JSON.stringify(res.data);
            localStorage.setItem("capacity_level", data);
            return true;
        },
        async business_type() {
            const res = await this.$http.getByDictType({
                dictType: "business_type",
            });
            let data = JSON.stringify(res.data);
            localStorage.setItem("business_type", data);
            return true;
        },
        async accreditation() {
            const res = await this.$http.getByDictType({
                dictType: "accreditation",
            });
            let data = JSON.stringify(res.data);
            localStorage.setItem("accreditation", data);
            return true;
        },
        async yn_dict() {
            const res = await this.$http.getByDictType({
                dictType: "yn_dict",
            });
            let data = JSON.stringify(res.data);
            localStorage.setItem("yn_dict", data);
            return true;
        },
    },
};
</script>

<style>
.container {
}

.el-header {
    height: 100px;
}

.el-aside {
    height: calc(100vh - 200px);
}

.el-main {
    padding: 0;
    height: calc(100vh - 200px);
}

.el-main .content {
    margin: 0 auto;
}

.header {
    width: 100%;
    height: 140px !important;
    /* background: #2ec9c3; */
    background: #5c5c5c;
    opacity: 1;
    line-height: 100px;
}

.logo {
    height: 140px;
    background: url("../assets/logo.png") 50% 100% no-repeat;
}

.header > div:first-child {
    width: 30%;
    text-align: center;
    line-height: 100px;
}

.header > div:nth-child(n + 1) {
    margin-right: 84px;
}

.menu {
    width: 33.885%;
    padding-right: 75px;
    box-sizing: border-box;
}

.menu > div {
    width: 350px;
    height: 50px;
    line-height: 50px;
    padding-left: 25px;
    box-sizing: border-box;
    font-weight: 400;
}

.menu .title {
    font-size: 18px;
    color: #ffffff;
    /* border: 1px solid #2ec9c3;
  background: #2ec9c3; */
    border: 1px solid #5c5c5c;
    background: #5c5c5c;
}

.menu .step1,
.menu .step2 {
    font-size: 14px;
    color: #4b5050;
    border: 1px solid #707070;
    border-top: none;
    background: #ffffff;
    cursor: pointer;
}

/* 	.menu .step1 {
		border-bottom: none;
	} */

.menu .back {
    background: rgb(92 92 92 / 30%);
}
</style>
